import axios from 'axios'
import type { Method, AxiosRequestConfig } from 'axios'
import { ElMessage } from 'element-plus'
import { getCookie } from '@/directives/imgLazy'
const service = axios.create({
  baseURL: '/primer',
  timeout: 1000 * 60 * 5,
})
// service.defaults.withCredentials = true
let elMessage: any

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 下一次请求关闭消息提示框
    if (elMessage) elMessage.close()
    // 在发送请求之前做些什么
    config.headers['Custom-Language'] =
      localStorage.getItem('Custom-Language') || 'zh' //默认是中文-+
    //console.log(getCookie('Authorization'));
    if (getCookie('Authorization')) {
      config.headers['Authorization'] = getCookie('Authorization')
    }

    return config
  },

  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
// 允许携带cookie

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // console.log(response.headers['set-cookie'])
    let data = response.data
    if (data.code === 500) {
      handleService500(data.msg)
      return
    }
    return data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)
// 处理500的错误
function handleService500(msg: string) {
  elMessage = ElMessage({
    message: msg,
    type: 'error',
  })
}

type Data<T> = {
  code: number
  msg: string
  data: T
}

function request<T>(
  url: string,
  method: Method,
  submitData?: any,
  config: AxiosRequestConfig = {}
) {
  return service.request<T, Data<T>>({
    url,
    method,
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData,
    ...config,
  })
}

export default request
